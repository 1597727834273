var AuthService = function (options) {
    var settings = options || {
        checkInterval: 5000,
        hasIdentityUrl: '/api/v1/auth/has-identity'
    };
//    _.extend(settings, defaults);

    this.getSettings = function () {
        return settings;
    }

    var lastCalled = null;

    this.getLastCalled = function () {
        return lastCalled;
    }

    this.setLastCalled = function (time) {
        lastCalled = time;
    }

//    _.extend(this, Backbone.Events);

    var me = this;
    this.hasIdentity = function (callback, force) {
        // console.log('hasIdentity');
        var force = force || false;
        //var me = this;
        var send = function (callback) {
            
            me.setLastCalled((new Date().getTime()));
            $.ajax({
                url: me.getSettings().hasIdentityUrl,
                dataType: 'json',
                type: 'post',
                success: function (resp) {
                    if (resp.data.identity) {
                        me.identity = resp.data.identity;
                    } else {
                        me.identity = me.emptyIdentity;
                    }
                    var obj = {'success': resp.success, 'identity': me.getIdentity()};
                    if (typeof callback === 'function') {
                        callback.call(me, resp.success, resp, me);
                    }
    //                me.trigger('auth:hasIdentity', obj);
                    
                }
            });
        };
        var distance = (new Date).getTime() - me.getLastCalled();
        if (force === true || (me.getLastCalled() === null || (distance >= me.getSettings().checkInterval))) {
            send(callback);
        }
    }
    
//        var timer = setInterval(function(){
//			if(timer !== null){
//				me.hasIdentity();
//			}
//        }, settings.checkInterval);

    this.getTimer = function () {
        return timer;
    }

    me.emptyIdentity = {
        userId: null,
        userName: null,
        state: false,
        avatar: '/new_img/none.png',
        firstName: '',
        lastName: '',
        email: '',
        hasPassword: false
    };
    me.identity = {
        userId: null,
        userName: null,
        state: false,
        avatar: '/new_img/none.png',
        firstName: '',
        lastName: '',
        email: '',
        hasPassword: false
    };
    this.getIdentity = function () {
        return {
            userId: me.identity.userId,
            userName: me.identity.userName,
            state: me.identity.userState,
            avatar: me.identity.userAvatar,
            firstName: me.identity.firstName,
            lastName: me.identity.lastName,
            email: me.identity.email,
            profile: me.identity.profile,
            hasPassword: me.identity.hasPassword,
            hasIdentity: function () {
                return this.userId != null;
            },
            isConfirmed: function () {
                return this.hasIdentity() && this.state;
            }
        }
    }
    me.hasIdentity();
    
    return this;
}


